export default [
  {
    title: "About",
    body: "I currently work at HCSC as an Associate Software Developer. I went to the University of Illinois at Chicago and have a Bachelors in Science in Computer Science.",
  },
  {
    title: "Interests",
    body: "Creating things is a long time interest of mine. I'm currently working with Microcontrollers trying to create projects! I've made a few websites such as a multiplayer online Rock Paper Scissors game. My projects are on my Github.",
  },
];
